<template>
  <div>
    <search-agent-renewed-simCard
      @submit="submitSearch"
      :agent-id="agentId"
    />

    <div class="iotplt-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >
      <span slot="product_price_title">
        <a-tooltip>
          <template slot="title">
            这里显示的是套餐周期单价，精确到两位小数
          </template>
          套餐价格(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import SearchAgentRenewedSimCard from '@/views/sim_cards/service_cycle_manage/renewed/Search'
import Pagination from '@/components/Pagination'
import { findAgentRenewedSimCards, exportAgentRenewedSimCards } from '@/api/service_cycle_manage'
import { formatCurrency } from '@/utils/filter'
import { exportExcel } from '@/api/excel'
export default {
  name: 'AgentRenewedSimCard',
  components: {
    SearchAgentRenewedSimCard,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '续期时间',
          dataIndex: 'created_at'
        },
        {
          title: '客户套餐',
          dataIndex: 'product_name'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          slots: { title: 'product_price_title' },
          dataIndex: 'product_price',
          customRender: formatCurrency
        },
        {
          title: '续期服务周期数',
          dataIndex: 'cycles'
        },
        {
          title: '续期金额(元)',
          dataIndex: 'total_fee',
          customRender: formatCurrency
        }
      ]
    },
    agentId() {
      if (this.$route.query.agent_id) {
        return parseInt(this.$route.query.agent_id)
      }

      return this.$store.getters.userAgentId
    }
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      const data = {
        agent_id: this.agentId,
        ...this.query,
        ...this.sort
      }
      findAgentRenewedSimCards(data).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    exportData() {
      exportAgentRenewedSimCards(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
